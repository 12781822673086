<template>
  <div>
    <div class="background-size text-white pt-5" style="background-image: url('/home/home.png'); background-size: cover">
      <div class="title">
        <h1 class="font-weight-bold" style="font-size: 6vw; color: white; text-align: right; margin-right: 20px;">Sewa Truk</h1>
        <p style="font-size: 1.8vw; color: white; text-align: right; margin-right: 20px;">Kirim Barang Dalam Kota, Antar Kota hingga Antar Provinsi</p>
      </div>
    </div>
    <div class="container-fluid p-0">
      <div class="row mx-md-5 mx-sm-4 mx-1 mb-5 p-3">
        <div class="col-lg-5 col-12" style="justify-content: center; display: flex;width: 100%;height: 100%;">
          <img src="/truck_provider/778px-Cartoon_Delivery_Truck_And_Workers 1 (1).png" style="max-width: 100%; height: auto" />
        </div>
        <div class="col-lg-7 col-12">
          <p style="font-size: 2rem; font-weight: bold;">Sewa Truk Di BAKAMA</p>
          <div style="font-size: 20px; line-height: 1.6;">
            <p>Layanan sewa truk yang sangat terkemuka, menawarkan solusi angkut barang yang efisien dan handal untuk kebutuhan pindahan atau pengiriman barang besar, berat, dan dalam jumlah yang banyak.</p>
            <p>Dengan fokus utama pada penyewaan truk, menyediakan berbagai pilihan kendaraan yang dapat disesuaikan untuk kebutuhan pelanggan, mulau dari truk kecil hingga truk besar.</p>
            <p>Nikmati pengalaman truk tanpa ribet dengan proses pemesanan yang mudah. Kamu dapat memilih truk sesuai dengan ukuran dan kapasitas beban barang yang Kamu Butuhkan.</p>
            <p style="color: #ad0e1a;">Bingung pakai truk apa? Tekan tombol dibawah ini untuk berkonsultasi dengan Customer</p>
          </div>
          <a @click="$router.push('kontak')" class="btn btn-light font-weight-bold mt-3" style="border-radius: 33px; width: 11rem; height: 4rem; font-size: 1rem; display: flex; align-items: center; justify-content: center">Hubungi kami</a>
        </div>
      </div>
    </div>
    <div class="container">
      <p class="d-flex justify-content-center font-weight-bold" style="font-size: 1.5rem;">Pilihan Kendaraan</p>
      <div class="row nav nav-tabs">
        <div class="col-md-3">
          <div class="nav-item">
            <a class="nav-link" :class="{ active: activeTab === 'tab1' }" @click="activeTab = 'tab1'">Granmax Blindvan</a>
          </div>
        </div>
        <div class="col-md-3">
          <div class="nav-item">
            <a class="nav-link" :class="{ active: activeTab === 'tab2' }" @click="activeTab = 'tab2'">Granmax Box</a>
          </div>
        </div>
        <div class="col-md-3">
          <div class="nav-item">
            <a class="nav-link" :class="{ active: activeTab === 'tab3' }" @click="activeTab = 'tab3'">Tipe CDE ( 4 Roda )</a>
          </div>
        </div>
        <div class="col-md-3">
          <div class="nav-item">
            <a class="nav-link" :class="{ active: activeTab === 'tab4' }" @click="activeTab = 'tab4'">Tipe CDD ( 6 Roda )</a>
          </div>
        </div>
      </div>
      <div class="tab-content">
        <div class="tab-pane fade" :class="{ active: activeTab === 'tab1'}">
          <div class="container-fluid">
            <div class="container">
              <div class="row mx-xl-5 mx-lg-4 mx-md-4 mx-sm-3 mx-2 px-0 px-xl-5 px-lg-3 px-md-1 px-sm-0 pt-2">
                <div class="col-md-6" style="justify-content: center;display: flex;">
                  <img src="/home/Rectangle 10.png" style="width:15rem; height: auto;"/>
                </div>
                <div class="col-md-6">
                  <p class="pane-title">Tipe Blindvan</p>
                  <div class="pane-text">
                    <li>Ukuran Maksimal Kargo : 100cm x 140cm x 80cm</li>
                    <li>Berat Maksimal : 600 Kg</li>
                    <li>Jenis Atap : Tertutup</li>
                  </div>
                  <button onclick="window.location.href='https://wa.me/6285156935175';" class="button mt-5">Pesan Sekarang<b-icon style="width: 1.3rem; height: auto; margin: 0px 7px 0px 10px" icon="arrow-right-circle"></b-icon></button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" :class="{ active: activeTab === 'tab2'}">
          <div class="container-fluid">
            <div class="container">
              <div class="row mx-xl-5 mx-lg-4 mx-md-4 mx-sm-3 mx-2 px-0 px-xl-5 px-lg-3 px-md-1 px-sm-0 pt-2">
                <div class="col-md-6" style="justify-content: center;display: flex;">
                  <img src="/home/Rectangle 10 (1).png" style="width:15rem; height: auto;"/>
                </div>
                <div class="col-md-6">
                  <p class="pane-title">Tipe Box</p>
                  <div class="pane-text">
                    <li>Ukuran Maksimal Kargo : 237cm x 155cm x 130cm</li>
                    <li>Berat Maksimal : 800 Kg</li>
                    <li>Jenis Atap : Tertutup</li>
                  </div>
                  <button class="button mt-5">Pesan Sekarang<b-icon style="width: 1.3rem; height: auto; margin: 0px 7px 0px 10px" icon="arrow-right-circle"></b-icon></button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" :class="{ active: activeTab === 'tab3' }">
          <div class="container-fluid">
            <div class="container">
              <div class="row mx-xl-5 mx-lg-4 mx-md-4 mx-sm-3 mx-2 px-0 px-xl-5 px-lg-3 px-md-1 px-sm-0 pt-2">
                <div class="col-md-6" style="justify-content: center;display: flex;">
                  <img src="/home/Rectangle 10 (2).png" style="width:15rem; height: auto;"/>
                </div>
                <div class="col-md-6">
                  <p class="pane-title">Tipe Engkel Box ( CDE )</p>
                  <div class="pane-text">
                    <li>Ukuran Maksimal Kargo : 350cm x 160cm x 160cm</li>
                    <li>Berat Maksimal : 2000 Kg</li>
                    <li>Jenis Atap : Tertutup</li>
                  </div>
                  <button class="button mt-5">Pesan Sekarang<b-icon style="width: 1.3rem; height: auto; margin: 0px 7px 0px 10px" icon="arrow-right-circle"></b-icon></button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" :class="{ active: activeTab === 'tab4' }">
          <div class="container-fluid">
            <div class="container">
              <div class="row mx-xl-5 mx-lg-4 mx-md-4 mx-sm-3 mx-2 px-0 px-xl-5 px-lg-3 px-md-1 px-sm-0 pt-2">
                <div class="col-md-6" style="justify-content: center;display: flex;">
                  <img src="/home/Rectangle 10 (3).png" style="width:15rem; height: auto;"/>
                </div>
                <div class="col-md-6">
                  <p class="pane-title">Tipe Double Box ( CDD )</p>
                  <div class="pane-text">
                    <li>Ukuran Maksimal Kargo : 420cm x 200cm x 170cm</li>
                    <li>Berat Maksimal : 4000 Kg</li>
                    <li>Jenis Atap : Tertutup</li>
                  </div>
                  <button class="button mt-5">Pesan Sekarang<b-icon style="width: 1.3rem; height: auto; margin: 0px 7px 0px 10px" icon="arrow-right-circle"></b-icon></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="p-3 d-flex justify-content-center">
      <div class="dashed-line"></div>
    </div>
    <div class="container">
      <div class="row mx-xl-5 mx-lg-5 mx-md-4 mx-sm-3 mx-3 mb-3 px-xl-3 px-lg-2 px-md-4 px-sm-3 px-1">
        <div class="col-lg-3 col-md-6 col-12">
          <div class="card">
            <img src="/truck_provider/Frame 128.png" alt="">
            <p class="title-card mb-lg-3 mb-md-1 mb-sm-0 mb-0">On Call / Ritase</p>
            <p class="card-text">Pengiriman barang yang disesuaikan dengan kebutuhan anda dengan jaminan keamanan barang dan tepat waktu.</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-12">
          <div class="card">
            <img src="/truck_provider/Frame 129.png" alt="">
            <p class="title-card mb-lg-3 mb-md-1 mb-sm-0 mb-0">Sewa Unit</p>
            <p class="card-text">Berbagai kendaraan Niaga dengan garansi kendaraan pengganti + GPS.</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-12">
          <div class="card">
            <img src="/truck_provider/Frame 118.png" alt="">
            <p class="title-card mb-lg-3 mb-md-1 mb-sm-0 mb-0">Sewa Unit dan Supir</p>
            <p class="card-text">Pengemudi yang terlatih dalam bidangnya</p>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-12">
          <div class="card">
            <img src="/truck_provider/Frame 123.png" alt="">
            <p class="title-card mb-lg-3 mb-md-1 mb-sm-0 mb-0">Customized Dedicated Transport Services</p>
            <p class="card-text">Layanan jasa pengiriman barang yang sesuai dengan bisnis anda.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid" style="background-color: #fbfbfb;">
      <div class="row row m-xl-5 m-lg-5 m-md-4 m-sm-4 m-3 mb-3" style="background-image: url('/truck_provider/img-8.jpg'); background-size: cover; border-radius: 20px; background-position: center;">
        <div class="col-md-12 p-xl-5 p-lg-2 p-md-4 p-sm-3 p-1" style="background-color: rgba(24, 24, 24, 0.7); border-radius: 20px; text-align: left; width: 100%; height: 100%;">
          <div class="row p-3" style="align-items: center;">
            <div class="col-md-8" style="color: white;">
              <p class="m-0" style="font-weight: bold; font-size: 2.8rem;">Butuh Pilihan Lain?</p>
              <p style="font-size: 0.9rem; font-weight: bold;">Buatlah jenis pesanan sendiri, kamu bisa mengajukan pesanan sesuai kebutuhan kamu...</p>
            </div>
            <div class="col-md-4" style="text-align: right;">
              <button @click="$router.push('kontak')" class="button-banner">Tanya Kami</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    data() {
    return {
      activeTab: 'tab1'
    };
  }
}
</script>

<style scoped>
  .button-banner:hover{
    border: #ad0e1a;
    background-color: #ad0e1a;
    transition: background-color 0.3s ease, border 0.5s ease;
  }
  .button-banner{
    background: transparent; 
    border-radius: 10px; 
    color: white; border: 
    solid; padding: 10px; 
    font-weight: bold; 
    width: 11rem; 
    height: 4rem;
  }
  .button {
    border-radius: 10px;
    width: 12rem;
    height: 2rem;
    font-size: 15px;
    border-color: #ad0e1a;
    display: flex;
    align-items: center;
    background-color: white;
    color: #ad0e1a;
    font-weight: bold;
    padding: 9px;
    justify-content: space-between;
    position: relative; /* Tambahkan posisi relatif */
    overflow: hidden; /* Sembunyikan konten yang keluar dari batas */
  }
     .button::before {
    content: '';
    position: absolute;
    top: 0%;
    left: 0%;
    width: 300%; /* Lebar 3x dari konten tombol */
    height: 300%; /* Tinggi 3x dari konten tombol */
    background: radial-gradient(circle, rgba(173, 14, 26,1), rgba(173, 14, 26, 1)); /* Gradient transparan */
    transition: transform 0.8s ease; /* Transisi transformasi */
    z-index: -1; /* Jadikan lebih tinggi dari konten tombol */
    pointer-events: none; /* Agar tidak mempengaruhi event pointer */
    transform: translate(-50%, -50%) scale(0); /* Sembunyikan dan ubah skala menjadi 0 secara default */
    border-radius: 50%; /* Buat bentuk oval */
  }

  .button:hover::before {
    transform: translate(-25%, -25%); /* Pindahkan gradient untuk mengalir ke kanan bawah saat dihover */
  }
    .button:hover {
    color: white; /* Ubah warna teks menjadi putih saat dihover */
    z-index: 1; /* Atur z-index agar konten muncul di atas pseudo-element */
  }
  




  
.btn-light:hover {
  background-color: #ad0e1a;
  color: white;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.nav-link {
  border: none;
}
.title-card {
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  font-weight: bold;
}
.card {
  width: 100%;
  height: 100%;
  border: none;
  text-align: center;
  font-size: 14px;
  color: black;
  margin-bottom: 2rem;
}
.card img {
  max-width: 100%;
  height: 8rem;
  align-self: center;
}
.dashed-line {
    border-top: 2px dashed #8b8989;
    width: 90%; 
    margin: 20px 0; 
  }
.title {
  padding-top: 10%;
  padding-right: 5%;
  padding-bottom: 25%;
  margin-left: auto;
}
.background-size {
  max-width: 100%;
  height: auto;
}
</style>

<style scoped>
  .nav-tabs {
    cursor: pointer;
    text-align: center;
    border: none;
  }
  .nav-tabs .nav-link {
    position: relative;
  }
  .nav-tabs .nav-link::after {
    content: '';
    display: block;
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: transparent;
    transition: background-color 0.3s ease;
  }
  .nav-tabs .nav-link.active::after {
    background: linear-gradient(to right, rgb(255, 94, 0), rgb(253, 207, 2));
    height: 0.3rem;
    /* width: 11rem; */
    border-radius: 10px;
    /* left: 15%; */
  }
  .nav-tabs .nav-link.active {
    color: #ad0e1a;
    font-weight: bold;
  }
  .nav-tabs .nav-link {
    color: black;
    font-weight: bold;
  }
  .tab-content {
    margin-top: 10px;
  }
  .pane-title{
    text-align: center;
    font-weight: bold;
    font-size: 1.3rem;
  }
  .pane-text{
    line-height: 2;
    font-size: 15px;
  }
  .tab-pane{
    animation: fadeIn 1.5s ease forwards;
  }
  .tab-pane.hidden {
  animation: fadeOut 1.5s ease forwards;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
</style>