<template>
  <footer>
    <div class="container-fluid">
      <div class="mx-5 pt-2 pb-2">
        <div class="row">
          <div class="footer-left col-md-4 col-sm-12 col-xs-4 mb-sm-4 mb-4 p-0">
            <div class="footer-text pull-left">
              <a class="logo" href="#">
                <img src="/navbar/BKM_Logo.png" alt="Brand Image" style="width: 200px;" />
              </a>
              <p class="mb-md-4 mb-sm-2 mb-2 mt-1">Jalan Heliconia Blok HP 1 no. 25, Pustaka Rakyat, Kec. Tarumajaya, Kab. Bekasi, Jawa Barat 17132</p>
              <p class="mb-0">Phone: +62 21 8889 6854</p>
            </div>
          </div>
          <div class="col-xl-4 col-lg-3 col-md-2"></div>
          <div class="col-xl-2 col-lg-2 col-md-3 col-sm-6 mb-sm-2 mb-4 py-3 p-0">
            <p class="mb-md-4 mb-sm-2 mb-2 title-content">Perusahaan</p>
            <RouterLink to="/profil-perusahaan" class="text-decoration-none text-white">
              <p class="mb-1">Tentang Perusahaan</p>
            </RouterLink>
            <RouterLink to="/kontak" class="text-decoration-none text-white">
              <p class="mb-1">Hubungi Kami</p>
            </RouterLink>
            <a href="https://www.google.com/maps/place/PT.+BATAVIA+KARYA+MANDIRI/@-6.168668,106.9800269,17z/data=!3m1!4b1!4m6!3m5!1s0x2e698bb5aaa6c707:0xfeece87914bbb928!8m2!3d-6.1686733!4d106.9826018!16s%2Fg%2F11vrsnyr3k?entry=ttu" target="_blank" class="text-decoration-none text-white">
              <p class="mb-0">Lokasi</p>
            </a>
          </div>
          <div class="col-xl-2 col-lg-3 col-md-3 col-sm-6 mb-sm-2 py-3 p-0">
            <p class="mb-md-4 mb-sm-2 mb-2 title-content">Customer Service</p>
            <p class="mb-1"><b-icon icon="phone"></b-icon> +62 851 5693 5175</p>
            <p class="mb-1"><b-icon icon="envelope-fill"></b-icon> rani@bakama.co.id</p>
            <!-- <div class="social-icons">
              <a href="#">
                <b-icon icon="instagram"></b-icon>
              </a>
              <a href="#">
                <b-icon icon="linkedin"></b-icon>
              </a>
              <a href="#">
                <b-icon icon="facebook"></b-icon>
              </a>
            </div> -->
          </div>
        </div>
        <div class="text-center">© 2024 PT. Batavia Karya Mandiri, Inc. All rights reserved.</div>
      </div>
    </div>
  </footer>
</template>

<script>
// import { BIcon } from "bootstrap-vue";

export default {
  name: "Footer",
  components: {
    // BIcon,
  },
};
</script>

<style scoped>
.container-fluid {
  background-color: #ad0e1a;
  font-size: 12px;
  color: white;
}

.social-icons a {
  margin-right: 25px;
  color: #fff;
  font-size: 20px;
}
.social-icons {
  padding-top: 20px;
}
.title-content {
  font-weight: bold;
  font-size: 15px;
}
</style>
