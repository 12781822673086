<template>
  <div class="" style="padding:10%;padding-top: calc(150px + 0.390625vw);">
    <div class="row">
        <div class="col-12 col-lg-6 text-justify-content">
            <h6 class="font-weight-bold mb-4" style="font-size: calc(30px + 0.390625vw); color: brown;">Hubungi Kami</h6>
            <h6 class="" style="font-size: calc(15px + 0.390625vw);">Jangan ragu untuk menghubungi kami</h6>
            <h6 class="" style="font-size: calc(15px + 0.390625vw);">dan temukan layanan terbaik</h6>
            <h6 class="" style="font-size: calc(15px + 0.390625vw);">untuk kebutuhan pengiriman Anda.</h6>
            
            <!-- content modal kirim pertanyaan -->
            <a v-b-modal.modal-1 class="btn font-weight-bold mt-4 text-justify-content mb-4" style="border-radius: 30px; width:40%; height: 10%; border: 2px solid black;">Kirim Pertanyaan</a>
            <b-modal id="modal-1" size="xl" hide-footer>
                <h6 class="font-weight-bold text-center" style="padding-bottom: calc(50px + 0.390625vw); font-size: calc(30px + 0.390625vw); color: brown;">Kirim Pertanyaan</h6>
                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <input v-model="namaLengkap" type="text" class="form-control input-modal" placeholder="Nama Lengkap">
                        </div>
                        <div class="form-group">
                            <input v-model="noHandphone" type="text" class="form-control input-modal" placeholder="Nomor Handphone">
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <input v-model="email" type="text" class="form-control input-modal" placeholder="Email">
                        </div>
                        <div class="form-group">
                            <select v-model="kategori" class="form-control input-modal">
                                <option value="">Select to</option>
                                <option value="Logistic Service">Logistic Service</option>
                                <option value="Finance Service">Finance Service</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <input v-model="judul" type="text" class="form-control input-modal" placeholder="Judul">
                        </div>
                        <div class="form-group">
                            <input v-model="komen" type="text" class="form-control input-modal" placeholder="Komen">
                        </div>
                    </div>
                </div>
                <a v-bind:href="'https://wa.me/6285156935175?text=Nama%20Lengkap%3A%20'+ namaLengkap +'%0AEmail%3A%20'+ email+'%0ANomor%20Handphone%3A%20'+noHandphone+'%0AKategori%3A%20'+kategori+'%0AJudul%3A%20'+judul+'%0AKomen%3A%20'+ komen" target="_blank">
                    <b-button variant="light" style="float:right;">Kirim</b-button>
                </a>
                <!-- <b-button class="mt-3 btn-dark" block @click="$bvModal.hide('bv-modal-example')">Kirim</b-button> -->
            </b-modal>
            <!-- content modal kirim pertanyaan -->

            <img src="/contact/pesawat.png" class="" alt="contact" style="width: 50%;">

            <p class="mb-3 mt-4 title-content">Kantor Pusat & Warehouse</p>
            <div>Jl. Heliconia Blok HP 1 N0.25, Pustaka Rakyat</div>
            <div>Jawa Barat 17132</div>
            <p class="mb-1 mt-3"><b-icon icon="phone"></b-icon> +62 851 5693 5175</p>
            <p class="mb-1"><b-icon icon="phone"></b-icon> +62 821 8889 6854</p>
            <p class="mb-1"><b-icon icon="envelope-fill"></b-icon> rani@bakama.co.id</p>
        </div>
        <div class="col-12 col-lg-6">
            <img src="/contact/contact.png" class="" alt="contact" style="width: 100%;">
        </div>
    </div>
  </div>
</template>

<script>
import { BIcon } from "bootstrap-vue";

export default {
    name: "Kontak",
    components: {
        BIcon,
    },
    data() {
        return {
            namaLengkap: "",
            email: "",
            kategori: "",
            noHandphone: "",
            judul: "",
            komen: ""
        }
    },
    methods: {
        
    },
}
</script>

<style>
    /* background modal */
    .modal-content {
        background-color: rgba(255, 255, 255, 0.800) !important;
        backdrop-filter: blur(10px) !important;
    }

    /* remove hr header */
    .modal-header {
        border-bottom: 0 none;
    }
    
    /* remove hr footer */
    .modal-footer {
        border-top: 0 none;
    }

    /* css for input modal kirim pertanyaan */
    .input-modal{
        border-radius: 10px;
        background-color: rgb(255 255 255 / 30%) !important;
        backdrop-filter: blur(10px) !important;
        height: 50px;
    }

    .text-justify-content {
        display: flex;
        flex-direction: column;
        justify-content: center; 
    }
    .title-content {
        font-weight: bold;
        font-size: 15px;
    }
</style>