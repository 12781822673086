import { render, staticRenderFns } from "./TruckProvider.vue?vue&type=template&id=71686b88&scoped=true"
import script from "./TruckProvider.vue?vue&type=script&lang=js"
export * from "./TruckProvider.vue?vue&type=script&lang=js"
import style0 from "./TruckProvider.vue?vue&type=style&index=0&id=71686b88&prod&scoped=true&lang=css"
import style1 from "./TruckProvider.vue?vue&type=style&index=1&id=71686b88&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71686b88",
  null
  
)

export default component.exports