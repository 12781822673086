<template>
  <div>
    <nav class="navbar navbar-expand-lg fixed-top navbar-light blur-effect p-0">
      <div class="container">
        <a class="navbar-brand" href="/">
          <img
            src="/navbar/BKM_Logo_new.png"
            alt="Brand Image"
            style="width: 200px"
          />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbar1"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbar1">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item dropdown active">
              <a
                class="nav-link dropdown-toggle font-weight-bold"
                href="#"
                id="navbarDropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Layanan Kami
              </a>
              <div
                class="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
                style="
                  background-color: rgb(255 255 255 / 30%);
                  backdrop-filter: blur(10px);
                "
              >
                <div class="receptacle">
                  <RouterLink to="/penyedia-trucking" class="dropdown-item">
                    Penyedia Trucking<i class="fa-solid fa-chevron-right"></i>
                  </RouterLink>
                </div>
              </div>
            </li>
          </ul>
          <ul class="navbar-nav ml-auto">
            <li class="nav-item dropdown active">
              <a
                class="nav-link dropdown-toggle font-weight-bold"
                href="#"
                id="navbarDropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Tentang Kami
              </a>
              <div
                class="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
                style="
                  background-color: rgb(255 255 255 / 30%);
                  backdrop-filter: blur(10px);
                "
              >
                <div class="receptacle">
                  <RouterLink to="/profil-perusahaan" class="dropdown-item">
                    <span>Profile Perusahaan</span>
                    <i class="fa-solid fa-chevron-right"></i></RouterLink>
                    <RouterLink to="/galeri" class="dropdown-item">
                    Galeri<i class="fa-solid fa-chevron-right"></i
                  ></RouterLink>
                </div>
              </div>
            </li>
          </ul>
          <ul class="navbar-nav ml-auto">
            <li class="nav-item active">
              <RouterLink to="/karir" class="nav-link font-weight-bold">Karir
                </RouterLink>
            </li>
          </ul>
          <ul class="navbar-nav ml-auto">
            <li class="nav-item active">
              <RouterLink to="/kontak" class="nav-link font-weight-bold">Hubungi</RouterLink>
            </li>
          </ul>
          <ul class="navbar-nav ml-auto financial">
            <li class="nav-item active">
              <a class="nav-link font-weight-bold" href="https://finance.bakama.co.id/"
                >Financial Service</a
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { RouterLink } from 'vue-router';

export default {
  components: {
    RouterLink,
  },
};
</script>

<style scoped>
.dropdown-menu a.dropdown-item:hover,
.dropdown-menu a.dropdown-item:focus {
  color: #ad0e1a;
  text-decoration: none;
  background-color: white;
  border-radius: 5px;
  border: none;
  width: auto;
}
.navbar-expand-lg .navbar-nav .dropdown-menu {
  border-radius: 0 15px 15px 15px;
}
a.dropdown-item {
  margin: 0;
  font-weight: bold;
  padding: 1rem;
  font-size: 0.9rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
}
.dropdown-item i {
  color: #ad0e1a;
}
.navbar {
  width: 90%;
  margin: 0 auto;
  top: 2% !important;
  display: flex;
  justify-content: center;
  border-radius: 13px;
}

.navbar-nav .nav-link:hover {
  color: rgb(161, 0, 0) !important;
}

.navbar-nav .nav-link.active::after {
  background: rgb(161, 0, 0) !important;
}

.blur-effect {
  background-color: rgb(255 255 255 / 30%);
  backdrop-filter: blur(10px);
}
@media (max-width: 991px) {
  .receptacle {
    padding: 0.5rem;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    border: none;
    padding: 0;
    width: 19rem;
  }
  .navbar-toggler {
    margin-right: 0.5rem;
  }
  .navbar-collapse {
    padding: 0 1rem 1rem 1rem;
  }
  .navbar-nav {
    padding-left: 10px;
  }
  .financial {
    background-color: rgba(240, 240, 240);
    border-radius: 5px;
  }
}

@media (min-width: 992px) {
  .receptacle {
    padding: 0.5rem;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    border: none;
    padding: 0;
    margin-top: 1.6rem;
    width: 19rem;
  }
  .financial {
    background-color: rgba(240, 240, 240);
    height: 5.8rem;
    width: 13vw;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
}
</style>
