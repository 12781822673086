<template>
  <div class="" style="padding-top: calc(150px + 0.390625vw); margin-bottom: 10%;padding-left: 5%; overflow: hidden;">
    <div class="row pr-4">
        <div class="col-12 col-lg-8">
            <h6 class="" style="font-size: calc(10px + 0.390625vw); color: brown;">Dengan pijakan yang kuat di industri ini</h6>
            <h6 class="" style="font-size: calc(20px + 0.390625vw);">Temukan peluang karir dan jadilah bagian tim kami.</h6>
            <div class="row" style="margin-top: 70px;">
                <div class="col-12 col-lg-6">
                    <img src="/career/career.png" class="" alt="career" style="width: 100%">
                </div>
                <div class="col-12 col-lg-6">
                    <div>Bakama sedang berkembang dan berharap</div>
                    <div>dapat bekerja sama dengan orang-orang</div>
                    <div>baru. Anda dapat mengirimkan resume Anda</div>
                    <div>melalui email ke</div>

                    <h6 class="font-weight-bold mb-4 mt-4" style="margin-left:20px; font-size: calc(20px + 0.390625vw); color: brown;">info@bakama.co.id</h6>
                    
                    <div>Pastikan untuk melampirkan CV atau</div>
                    <div>resume Anda dan sebutkan posisi yang</div>
                    <div>Anda inginkan.</div>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-4">
            <img src="/career/discuss.png" class="img-discuss" alt="career" style="width: 65%;">
            <img src="/career/laptop.png" class="img-laptop" alt="career" style="width: 50%;">
        </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
    /* .img-discuss{
        position: absolute;
    } */
    /* .img-laptop{
        position: absolute;
        top:350px;
        left: -90px;
    } */

     /* css view */
  @media only screen and (min-width:320px)
  { 
    /* smartphones, iPhone, portrait 480x320 phones */ 
    .img-discuss{
        position: relative;
    }
    .img-laptop{
        position: relative;
    }
  }
  @media only screen and (min-width:481px)  
  { 
    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ 
  }
  @media only screen and (min-width:641px)  
  { 
    /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ 
  }
  @media only screen and (min-width:961px)  
  { 
    /* tablet, landscape iPad, lo-res laptops ands desktops */ 

  }
  @media only screen and (min-width:1025px) 
  { 
    /* big landscape tablets, laptops, and desktops */ 
    .img-discuss{
        position: absolute;
    }
    .img-laptop{
        position: absolute;
        top:50%;
        right: 60%;
    }
  }
  @media only screen and (min-width:1281px) 
  { 
    /* hi-res laptops and desktops */
    .img-discuss{
        position: absolute;
    }
    .img-laptop{
        position: absolute;
        top:50%;
        right: 60%;
    }
  }
</style>