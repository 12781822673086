<template>
  <div>
    <div
      class="background-size text-white pt-5"
      style="
        background-image: url('/home/Gambar 1.png');
        background-size: cover;
      "
    >
      <div class="title">
        <h6 class="font-weight-bold" style="font-size: 4vw">
          Logistik yang mengerti
        </h6>
        <h6 class="font-weight-bold" style="font-size: 4vw">kebutuhan anda</h6>
        <h6 style="font-size: 3vw">Selalu aman dan tepat waktu.</h6>
        <a @click="$router.push('kontak')" class="btn btn-light button-start">Hubungi Kami</a>
      </div>
    </div>
    <div style="background-color: #fbfbfb">
      <div class="container-fluid position-card p-lg-3 p-md-2 p-0">
        <div
          class="row mx-xl-4 mx-lg-3 mx-md-3 mx-sm-0 mx-0 px-xl-0 px-lg-0 px-md-0 px-sm-0 px-0"
        >
          <div class="col-lg-4 col-md-6 col-sm-12 col-12 p-0">
            <div class="gutter">
              <div class="card detail-box">
                <img class="card-img-top" src="/home/Remove-bg.ai_1711481496848.png"/>
                <div class="limit"></div>
                <div class="detail-card">
                  <p class="title-card">Pengiriman Barang</p>
                  <p class="desc-card">
                    Bakama dibuat untuk memberikan kemudahan dalam memesan Jasa
                    Kirim Barang atau Jasa Pindahan dalam hitungan menit.
                    Melalui langkah sederhana dan biarkan Pengemudi Profesional
                    Kami menangani proses Kirim Barang atau Pindahan Anda.
                    Barang Besar, Berat dan Banyak? Kami Siap Angkut!
                  </p>
                  <button @click="$router.push('penyedia-trucking')" class="button content-1">
                    <b-icon
                      style="
                        width: 25px;
                        height: 25px;
                        margin-right: 7px;
                      "
                      icon="arrow-right-circle"
                    ></b-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 col-12 p-0">
            <div class="gutter">
              <div class="card detail-box">
                <img class="card-img-top" src="/home/Property_1_Default__2_-removebg-preview.png"/>
                <div class="limit"></div>
                <div class="detail-card">
                  <p class="title-card">Logistik dan Gudang</p>
                  <p class="desc-card">
                    Kami memiliki platform logistik dan gudang. Logistik barang
                    fisik kami melibatkan integrasi arus informasi, penanganan
                    material, produksi, pengemasan, transportasi, pergudangan,
                    dan sering kali keamanan.
                  </p>
                  <button class="button content-1">
                    <b-icon
                      style="
                        width: 25px;
                        height: 25px;
                        margin-right: 7px;
                      "
                      icon="arrow-right-circle"
                    ></b-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12 col-12 p-0">
            <div class="gutter">
              <div class="card detail-box">
                <img class="card-img-top" src="/home/Property_1_Default__1_-removebg-preview.png"/>
                <div class="limit"></div>
                <div class="detail-card">
                  <p class="title-card">Pengiriman Jalur</p>
                  <p class="desc-card">
                    Menyediakan layanan yang sesuai dengan kebutuhan spesifik
                    terkait transportasi ke semua outlet diseluruh indonesia dan
                    kami bisa bantu pengiriman paket jalur udara.
                  </p>
                  <button class="button content-1">
                    <b-icon
                      style="
                        width: 25px;
                        height: 25px;
                        margin-right: 7px;
                      "
                      icon="arrow-right-circle"
                    ></b-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid" style="background-color: white">
      <div
        class="row mx-xl-5 mx-lg-5 mx-md-4 mx-sm-3 mx-3 mb-3 px-xl-3 px-lg-2 px-md-4 px-sm-3 px-1 justify-content-center"
      >
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
          <img
            src="/home/transparent-transport-garbage-truck-vehicle-package-delivery-t-ergin5e6b3e9b92ec69 1.png"
            style="max-width: 100%; height: auto"
          />
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 p-1">
          <p style="font-size: 1.8rem; font-weight: bold">Apa itu Bakama?</p>
          <div class="mb-3">
            <img
              src="/home/Frame 88.png"
              alt=""
              style="width: 50%; height: 100%"
            />
          </div>
          <div style="line-height: 1.5; font-size: 1rem">
            <p>
              Kami menyediakan layanan logistik berupa layanan penyewaan
              kendaraan seperti Sewa Pick up, Sewa Blindvan dan Sewa Truk.
            </p>
            <br />
            <p>
              Tidak hanya menyewakan saja, tapi kami juga melayani Jasa Kirim
              Barang, seperti Kirim Barang Dalam dan Luar Kota. Bahkan Kami
              dapat memudahkan bisnis Anda, dengan membantu Distribusi
              Barang/Produk Anda. Kami menerima Angkut, Kirim, dan Distribusi
              barang mulai dari ukuran kecil hingga besar bahkan berat dan dalam
              jumlah sedikit hingga banyak.
            </p>
            <br />
            <p>
              Selain sewa kendaraan dan kirim barang, Kami juga melayani jasa
              pindah seperti Jasa Pindahan Rumah, Kantor, Apartemen dan Kos.
              Namun tidak termasuk bongkar, pasang, dan packing serta tata
              barang dalam ruangan.
            </p>
            <p style="opacity: 0.5">
              Kami siap untuk kebutuhan Angkut, Kirim, Distribusi Barang atau
              Pindahan Anda sekarang juga!
            </p>
          </div>
          <button @click="$router.push('profil-perusahaan')" class="button content-2">
            <b-icon
              style="width: 25px; height: 25px; margin-right: 7px;"
              icon="arrow-right-circle"
            ></b-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="container-fluid p-0" style="background-color: #fbfbfb">
      <div
        class="m-xl-5 m-lg-5 m-md-4 m-sm-3 m-3 mb-3 p-xl-3 p-lg-2 p-md-4 p-sm-3 p-1"
      >
        <p style="font-size: 25px; font-weight: bold; margin-bottom: 0px">
          Panduan untuk menentukan armada anda
        </p>
        <p style="font-size: 20px">
          Mulai dari armada jenis kendaraan van hingga truk besar
        </p>
        <div class="row justify-content-center">
          <div class="col-lg-3">
            <b-card no-body class="detail-car">
              <img src="/home/Rectangle 10.png" />
              <button
                class="detail-toggle"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding: 10px 20px;
                "
                block
                v-b-toggle.accordion-1
                variant="info"
              >
                Tipe Granmax Blindvan
                <span class="when-open"
                  ><b-icon
                    style="width: 35px; height: 35px; margin-left: 10px"
                    icon="arrow-up-circle"
                  ></b-icon></span
                ><span class="when-closed"
                  ><b-icon
                    style="width: 35px; height: 35px; margin-left: 10px"
                    icon="arrow-down-circle"
                  ></b-icon
                ></span>
              </button>
              <b-collapse id="accordion-1" visible role="tabpanel">
                <b-card-body style="padding-top: 5px">
                  <b-card-text>
                    <div style="font-size: 13px">
                      <p>
                        Berat Max.: 600 kg Ukuran Max.: 100cm x 140cm x 80cm.<br />
                      </p>
                      <p style="font-weight: bold">
                        Memiliki ukuran bagasi yang kecil namun mampu membawa
                        bobot berat seperti furniture kecil maupun barang kecil
                        dengan jumlah banyak.
                      </p>
                    </div>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
          <div class="col-lg-3">
            <b-card no-body class="detail-car">
              <img src="/home/Rectangle 10 (1).png" />
              <button
                class="detail-toggle"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding: 10px 20px;
                "
                block
                v-b-toggle.accordion-2
                variant="info"
              >
                Tipe Granmax Box
                <span class="when-open"
                  ><b-icon
                    style="width: 35px; height: 35px; margin-left: 10px"
                    icon="arrow-up-circle"
                  ></b-icon></span
                ><span class="when-closed"
                  ><b-icon
                    style="width: 35px; height: 35px; margin-left: 10px"
                    icon="arrow-down-circle"
                  ></b-icon
                ></span>
              </button>
              <b-collapse id="accordion-2" visible role="tabpanel">
                <b-card-body style="padding-top: 5px">
                  <b-card-text>
                    <div style="font-size: 13px">
                      <p>
                        Berat Max. 800 kg Ukuran Max.: 237cm x 155cm x 130cm.<br />
                      </p>
                      <p style="font-weight: bold">
                        Biasa digunakan untuk pindahan kos ataupun kegiatan
                        bisnis.
                      </p>
                    </div>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
          <div class="col-lg-3">
            <b-card no-body class="detail-car">
              <img src="/home/Rectangle 10 (2).png" />
              <button
                class="detail-toggle"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding: 10px 20px;
                "
                block
                v-b-toggle.accordion-3
                variant="info"
              >
                Tipe CDE ( 4 Roda )
                <span class="when-open"
                  ><b-icon
                    style="width: 35px; height: 35px; margin-left: 10px"
                    icon="arrow-up-circle"
                  ></b-icon></span
                ><span class="when-closed"
                  ><b-icon
                    style="width: 35px; height: 35px; margin-left: 10px"
                    icon="arrow-down-circle"
                  ></b-icon
                ></span>
              </button>
              <b-collapse id="accordion-3" visible role="tabpanel">
                <b-card-body style="padding-top: 5px">
                  <b-card-text>
                    <div style="font-size: 13px">
                      <p>
                        Berat Max. 2000 kg Ukuran Max. 350cm x 160cm x 160cm.<br />
                      </p>
                      <p style="font-weight: bold">
                        Cocok untuk pindahan rumah maupun angkut barang skala
                        bisnis.
                      </p>
                    </div>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
          <div class="col-lg-3">
            <b-card no-body class="detail-car">
              <img src="/home/Rectangle 10 (3).png" />
              <button
                class="detail-toggle"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding: 10px 20px;
                "
                block
                v-b-toggle.accordion-4
                variant="info"
              >
                Tipe CDD ( 6 Roda )
                <span class="when-open"
                  ><b-icon
                    style="width: 35px; height: 35px; margin-left: 10px"
                    icon="arrow-up-circle"
                  ></b-icon></span
                ><span class="when-closed"
                  ><b-icon
                    style="width: 35px; height: 35px; margin-left: 10px"
                    icon="arrow-down-circle"
                  ></b-icon
                ></span>
              </button>
              <b-collapse id="accordion-4" visible role="tabpanel">
                <b-card-body style="padding-top: 5px">
                  <b-card-text>
                    <div style="font-size: 13px">
                      <p>
                        Berat Max. 4000kg Ukuran Max. 420cm x 200cm x 170cm.<br />
                      </p>
                      <p style="font-weight: bold">
                        Angkut barang besar dan berat maupun pindah rumah dengan
                        furnitur besar hingga membantu mobilisasi barang untuk
                        bisnis.
                      </p>
                    </div>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid p-0">
      <div
        class="row m-xl-5 m-lg-4 m-md-4 m-sm-3 m-2 p-0 p-xl-0 p-lg-3 p-md-1 p-sm-0 justify-content-center"
      >
        <div class="col-lg-6 col-12 m-0 p-0">
          <div class="gutter">
            <div class="p-sm-5 p-4"
              style="
                background-image: url('/home/img-3.png');
                border-radius: 20px;
                background-size: cover;
                justify-content: center;
                display: flex;
                align-items: center;
                height: 100%;
              "
            >
              <div
                class="card p-xl-5 p-lg-5 p-md-5 p-sm-5 p-4"
                style="
                  background-color: rgba(173, 14, 26, 0.5);
                  backdrop-filter: blur(8px);
                  border-radius: 20px;
                  text-align: left;
                  width: 100%;
                  height: 100%;
                "
              >
                <p style="color: white; font-size: 25px; font-weight: bold">
                  Galeri
                </p>
                <p style="color: white">
                  Kunjungi situs website kami untuk melihat tentang bakama
                </p>
                <button class="button content-3" @click="$router.push('galeri')">
                  <b-icon
                    style="width: 25px; height: 25px; margin-right: 7px;"
                    icon="arrow-right-circle"
                  ></b-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-12 m-0 p-0">
          <div class="gutter">
            <div class="p-sm-5 p-4"
              style="
                background-image: url('/home/img-2.jpg');
                border-radius: 20px;
                background-size: cover;
                justify-content: center;
                display: flex;
                align-items: center;
                height: 100%;
              "
            >
              <div
                class="card p-xl-5 p-lg-5 p-md-5 p-sm-5 p-4"
                style="
                  background-color: rgba(173, 14, 26, 0.5);
                  backdrop-filter: blur(8px);
                  border-radius: 20px;
                  text-align: left;
                  width: 100%;
                  height: 100%;
                "
              >
                <p style="color: white; font-size: 25px; font-weight: bold">
                  Berkarir dengan Bakama
                </p>
                <p style="color: white">
                  Kami menyukai apa yang kami lakukan untuk mengubah dunia
                  kerja, dan kami pikir Anda juga akan menyukainya.
                </p>
                <button class="button content-3" @click="$router.push('karir')">
                  <b-icon
                    style="width: 25px; height: 25px; margin-right: 7px;"
                    icon="arrow-right-circle"
                  ></b-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid pl-xl-5 pl-lg-4 pl-md-3 pl-sm-2 pl-0">
      <div class="row ml-xl-1 ml-lg-3 ml-md-3 ml-sm-3 ml-2 p-0">
        <div class="col-12">
          <P class="m-0" style="font-size: 25px; font-weight: bold"
            >Handling Experience</P
          >
          <P
            >Kami sangat ber-Terima kasih banyak kepada mereka, tanpa mereka
            kami tidak ada di sini hari ini.</P
          >
        </div>
      </div>
    </div>

    <div class="container-fluid p-0 mb-5">
      <div class="slider">
        <div class="slide-track">
          <div class="slide">
            <img
              src="/home/Logo Aqua Japan 2.png"
              height="180"
              width="300"
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src="/home/DHL-Express-Indonesia-1200px-logo 1.png"
              height="180"
              width="300"
              alt=""
            />
          </div>
          <div class="slide">
            <img src="/home/favicon 1 br.png" height="180" width="200" alt="" />
          </div>
          <div class="slide">
            <img src="/home/Frame 53.png" height="180" width="300" alt="" />
          </div>
          <div class="slide">
            <img src="/home/Frame 57.png" height="180" width="300" alt="" />
          </div>
          <div class="slide">
            <img
              src="/home/Logo Aqua Japan 2.png"
              height="180"
              width="300"
              alt=""
            />
          </div>
          <div class="slide">
            <img
              src="/home/DHL-Express-Indonesia-1200px-logo 1.png"
              height="180"
              width="300"
              alt=""
            />
          </div>
          <div class="slide">
            <img src="/home/favicon 1 br.png" height="180" width="200" alt="" />
          </div>
          <div class="slide">
            <img src="/home/Frame 53.png" height="180" width="300" alt="" />
          </div>
          <div class="slide">
            <img src="/home/Frame 57.png" height="180" width="300" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BIcon } from "bootstrap-vue";

export default {
  components: {
    BIcon,
  },
};
</script>

<style scoped>
.btn-light:hover {
  background-color: #ad0e1a;
  color: white;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.gutter {
  height: 100%;
  padding: 20px;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
.detail-toggle {
  background-color: transparent;
  color: #ad0e1a;
  font-weight: bold;
  border: none;
}
.detail-car img {
  width: auto;
  height: 300px;
  object-fit: cover;
}
.detail-car {
  border-radius: 20px;
  border: 0px;
}
.detail-car button {
  background-color: transparent;
  color: #ad0e1a;
  font-weight: bold;
  border: transparent;
  justify-content: center;
  align-items: center;
}
.card-text {
  width: 100%;
  display: inline-block;
  border-top: 2px solid #ffbc1f;
  padding-top: 10px;
  text-align: center;
}
.detail-box {
  width: 100%;
  height: 100%;
  border-radius: 30px;
  margin-bottom: 20px;
  box-shadow: -5px -5px 15px rgba(0, 0, 0, 0.001),
    5px -5px 15px rgba(0, 0, 0, 0.1), -5px 5px 15px rgba(0, 0, 0, 0.1),
    5px 5px 15px rgba(0, 0, 0, 0.001);
}
.detail-box img:hover {
  transform: scale(1.1);
}
.detail-card {
  padding: 0 1.7rem 1.7rem 1.7rem;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.title-card {
  font-size: 22px;
  font-weight: bold;
  color: black;
}
.desc-card {
  font-size: 16px;
  font-weight: 500;
  opacity: 0.7;
  line-height: 1.8;
}
.content-1 {
  background-color: #ad0e1a;
  color: white;
  width: 12.5rem;
}
.content-2 {
  background-color: #ad0e1a;
  color: white;
  width: 12.5rem;
}
.content-3 {
  background-color: white;
  color: black;
  width: 11.8rem;
}
.content-3:hover {
  color: #ad0e1a;
}
.button.content-1::before {
  content: "Pelajari Lebih Lanjut";
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-weight: bold;
}
.button.content-2::before {
  content: "Pelajari Lebih Lanjut";
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-weight: bold;
}
.button.content-3::before {
  content: "Lihat Lebih Lanjut";
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-weight: bold;
}
.button {
  position: relative;
  border-radius: 10px;
  height: 3rem;
  font-size: 0.8rem;
  border-color: transparent;
  display: flex;
  align-items: center;
  justify-content: right;
  transition: width 0.5s ease;
  margin-top: auto;
}
.button:hover {
  width: 13.5rem;
}
.button:hover b-icon {
  transform: translateX(10px);
}
/* .position-card {
    position: relative;
    width: 100%;
    top: -10rem;
  } */
.background-size {
  max-width: 100%;
  height: auto;
}
</style>

<style>
@-webkit-keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
.slider {
  background: white;
  height: 180px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.slider .slide-track {
  animation: scroll 10s linear infinite;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  min-width: 200%;
  position: absolute;
}

.slider .slide {
  height: 180px;
  margin-left: 80px;
}

@media only screen and (min-width: 320px) {
  .limit {
    width: 100%;
    height: 3.7rem;
    box-shadow: 0px -13px 20px -10px rgba(0, 0, 0, 0.3);
  }
  .detail-box img {
    width: 100%;
    height: 20rem;
    transition: transform 0.5s ease;
    object-fit: cover;
    padding: 1rem 1rem 0 1rem;
  }
  .title {
    padding-top: 19%;
    padding-left: 5%;
    padding-bottom: 17%;
    margin-left: auto;
  }
  .position-card {
    position: relative;
    width: 100%;
    top: -5rem;
  }
  .button-start {
    border-radius: 1rem;
    width: 6rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    font-weight: bold;
    font-size: 0.6rem;
  }
}
@media only screen and (min-width: 481px) {
  .limit {
    width: 100%;
    height: 3.7rem;
    box-shadow: 0px -13px 20px -10px rgba(0, 0, 0, 0.3);
    margin-top: 1rem;
  }
  .detail-box img {
    width: 100%;
    height: 21rem;
    transition: transform 0.5s ease;
    object-fit: cover;
    padding: 1rem 1rem 0 1rem;
  }
  .title {
    padding-top: 16%;
    padding-left: 5%;
    padding-bottom: 16%;
    margin-left: auto;
  }
  .button-start {
    border-radius: 2rem;
    width: 7rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    font-weight: bold;
    font-size: 0.7rem;
    margin-top: 0.5rem;
  }
}
@media only screen and (min-width: 641px) {
  .limit {
    width: 100%;
    height: 3.7rem;
    box-shadow: 0px -13px 20px -10px rgba(0, 0, 0, 0.3);
    margin-top: 1rem;
  }
  .detail-box img {
    width: 100%;
    height: 21rem;
    transition: transform 0.5s ease;
    object-fit: cover;
    padding: 1rem 1rem 0 1rem;
  }
  .title {
    padding-top: 10%;
    padding-left: 5%;
    padding-bottom: 20%;
    margin-left: auto;
  }
  .position-card {
    position: relative;
    width: 100%;
    top: -6rem;
  }
  .button-start {
    border-radius: 2rem;
    width: 9rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    font-weight: bold;
    font-size: 0.9rem;
    margin-top: 1rem;
  }
}
@media only screen and (min-width: 961px) {
  .limit {
    width: 100%;
    height: 3.7rem;
    box-shadow: 0px -13px 20px -10px rgba(0, 0, 0, 0.3);
  }
  .detail-box img {
    width: 100%;
    height: 23rem;
    transition: transform 0.5s ease;
    object-fit: cover;
    padding: 1rem 1rem 0 1rem;
  }
  .position-card {
    position: relative;
    width: 100%;
    top: -8rem;
  }
  .button-start {
    border-radius: 2rem;
    width: 12rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    font-weight: bold;
    font-size: 1.1rem;
    margin-top: 2rem;
  }
}
@media only screen and (min-width: 992px) {
    .detail-box img {
    width: 100%;
    height: 19rem;
    transition: transform 0.5s ease;
    object-fit: cover;
    padding: 1rem 1rem 0 1rem;
  }
}
@media only screen and (min-width: 1281px) {
  .limit {
    width: 100%;
    height: 3.7rem;
    box-shadow: 0px -13px 20px -10px rgba(0, 0, 0, 0.3);
  }
  .detail-box img {
    width: 100%;
    height: 24rem;
    transition: transform 0.5s ease;
    object-fit: cover;
    padding: 1rem 1rem 0 1rem;
  }
  .title {
    padding-top: 10%;
    padding-left: 5%;
    padding-bottom: 20%;
    margin-left: auto;
  }
  .position-card {
    position: relative;
    width: 100%;
    top: -10rem;
  }
  .button-start {
    border-radius: 2rem;
    width: 12rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    font-weight: bold;
    font-size: 1.1rem;
    margin-top: 2rem;
  }
}
</style>
