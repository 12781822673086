<template>
  <div style="padding-top: 150px;">
    <!-- <p class="text-center" style="color: orange;">Di atas segalanya, Bakama ingin menjadi</p> -->
    <p class="text-center" style="font-size: calc(30px + 0.390625vw);">Kami siap memberikan transportasi yang Aman dan Ontime.</p>
    <!-- <img src="/galery/Vector_8.png" class="vector-8" alt="img-slider">
    <img src="/galery/Vector_12.png" class="vector-12" alt="img-slider"> -->

    <div class="row mt-4" style="padding-top:60px; padding-left: 60px; padding-right: 60px;">
      <div class="col-lg zoom mb-4">
        <img src="/galery/img-1.jpg" class="img-slider" alt="img-slider">
      </div>
      <div class="col-lg zoom mb-4">
        <img src="/galery/img-2.jpg" class="img-slider" alt="img-slider">
      </div>
      <div class="col-lg zoom mb-4">
        <img src="/galery/img-3.jpg" class="img-slider" alt="img-slider">
      </div>
      <div class="col-lg zoom mb-4">
        <img src="/galery/img-4.jpg" class="img-slider" alt="img-slider">
      </div>
    </div>

    <div class="row mt-4 mb-4" style="padding-left: 60px; padding-right: 60px; padding-bottom: 60px;">
      <div class="col-lg zoom mb-4">
        <img src="/galery/img-5.jpg" class="img-slider" alt="img-slider">
      </div>
      <div class="col-lg zoom mb-4">
        <img src="/galery/img-6.png" class="img-slider" alt="img-slider">
      </div>
      <div class="col-lg zoom mb-4">
        <img src="/galery/img-7.jpg" class="img-slider" alt="img-slider">
      </div>
      <div class="col-lg zoom mb-4">
        <img src="/galery/img-8.jpg" class="img-slider" alt="img-slider">
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
  .img-slider {
    width: 100%; 
    height:250px; 
    border-radius: 10px;
    object-fit: cover;
  }

  .zoom {
    /* padding: 50px; */
    transition: transform .5s;
    /* width: 200px;
    height: 200px; */
    /* margin: 0 auto; */
  }

  .zoom:hover {
    -ms-transform: scale(1.2); /* IE 9 */
    -webkit-transform: scale(1.2); /* Safari 3-8 */
    transform: scale(1.2); 
    z-index: 1;
  }

  /* .vector-8{
    position: absolute;
  } */
</style>