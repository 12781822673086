<template>
  <div id="app">
    <div id="red-line"></div>
    <Navbar />
    <!-- <img alt="Vue logo" src="./assets/logo.png" />
    <br />
    <img alt="Vue logo" src="./assets/logo.png" />
    <br />
    <img alt="Vue logo" src="./assets/logo.png" />
    <br />
    <img alt="Vue logo" src="./assets/logo.png" /> -->
    <!-- <a href="https://wa.me/6281318541847?text=Isi Pesan" target="_blank"> -->
    <a href="https://wa.me/6285156935175" target="_blank">
      <div class="btn fixed" style="border-radius: 50%;">
        <img src="/whatsapp.png" class="header-cart" alt="Whatsapp Icon" width="70" />
      </div>
    </a>
    <router-view>
    </router-view>
    <Footer />
  </div>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "App",
  components: {
    Navbar,
    Footer,
  },
};
</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* text-align: center; */
    /* color: #2c3e50; */
    /* margin-top: 60px; */
    position: relative;  /* membuat posisi menjadi relatif untuk garis coklat di samping kanan */
  }
  #red-line {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%; /* Menyesuaikan tinggi kontainer */
    width: 15px; /* Lebar garis */
    background-color: brown; /* Warna garis */
    z-index: 999; /* Mengatur z-index agar tetap di atas konten */
  }
  .header-cart {
    position: absolute;
    display: inline-block;
    /* margin-right: 40px; */
  }

.fixed {
    position: fixed;
    /* background: #FFBD59; */
    width: 9ex;
    height: 9ex;
    display: flex;
    justify-content: center;
    align-items: center;
    /* box-shadow: 0 0 6px #000; */
    color: #fff;
    z-index: 1;
    border-radius: 50%;
    top: 80%;
    right: 7%;
}
</style>
