import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home/Home.vue";
import Contact from "./views/Contact/Contact.vue";
import Career from "./views/Career/Career.vue";
import CompanyProfile from "./views/CompanyProfile/CompanyProfile.vue";
import Galery from "./views/Galery/Galery.vue";
import TruckProvider from "./views/TruckProvider/TruckProvider.vue";

//IMPORTROUTE

Vue.use(Router);

// const ifNotAuthenticated = (to, from, next) => {
// 	if (!store.getters.isLoggedIn) {
// 		next();
// 		return;
// 	}
// 	next("/");
// 	// let urlParams = new URLSearchParams(window.location.search);
// 	// let refParam = urlParams.get('ref');
// 	// next(refParam);
// }

// const authGuard = (to, from, next) => {
// 	console.log('authGuard: ', from);
// 	next();
// }

// const baseUrl = process.env.VUE_APP_BASE_FOLDER;
// const baseUrl = window.location.origin; di comment karna kalo di nyalain ketika buat $router.push jadi double url
export default new Router({
  // base: baseUrl,
  mode: "history",
  // mode: 'hash',
  routes: [
    // {
    // 	path: "/login",
    // 	name: "login",
    // 	component: LoginIndex,
    // 	beforeEnter: ifNotAuthenticated,
    // },
    {
      path: "/",
      name: "home",
      component: Home,
      // beforeEnter: authGuard,
    },
    {
      path: "/karir",
      name: "karir",
      component: Career,
      // beforeEnter: authGuard,
    },
    {
      path: "/kontak",
      name: "kontak",
      component: Contact,
      // beforeEnter: authGuard,
    },
    {
      path: "/profil-perusahaan",
      name: "profilperusahaan",
      component: CompanyProfile,
      // beforeEnter: authGuard,
    },
    {
      path: "/galeri",
      name: "galeri",
      component: Galery,
      // beforeEnter: authGuard,
    },
    {
      path: "/penyedia-trucking",
      name: "penyediatrucking",
      component: TruckProvider,
      // beforeEnter: authGuard,
    },
    // {
    // 	path: "/inbox",
    // 	name: "inboxindex",
    // 	component: InboxIndex,
    // 	beforeEnter: authGuard,
    // 	children: [

    // 	]
    // },
    // ROUTE IADOCUMENT START
    // {
    // 	path: "/iadocument",
    // 	name: "iadocumentindex",
    // 	component: IaDocumentIndex,
    // 	beforeEnter: authGuard,
    // 	children: [
    // 		{
    // 			path: "/iadocument/create",
    // 			name: "iadocumentcreate",
    // 			component: IaDocumentInput,
    // 			beforeEnter: authGuard
    // 		},
    // 		{
    // 			path: "/iadocument/create/:id/copydata/:copydata",
    // 			name: "iadocumentcopydata",
    // 			component: IaDocumentInput,
    // 			beforeEnter: authGuard
    // 		},
    // 		{
    // 			path: "/iadocument/edit/:id",
    // 			name: "iadocumentedit",
    // 			component: IaDocumentInput,
    // 			beforeEnter: authGuard
    // 		},
    // 		{
    // 			path: "/iadocument/detail/:id",
    // 			name: "iadocumentdetail",
    // 			component: IaDocumentDetail,
    // 			beforeEnter: authGuard
    // 		},
    // 	]
    // },
    // {
    // 	path: "/iadocument/inbox",
    // 	name: "iadocumentinbox",
    // 	component: IaDocumentInbox,
    // 	beforeEnter: authGuard,
    // 	children: [
    // 		{
    // 			path: "/iadocument/createinboxrequest/:id",
    // 			name: "iadocumentcreateinboxrequest",
    // 			component: IaDocumentInputSubmission,
    // 			beforeEnter: authGuard,
    // 		},
    // 	]
    // },
    // {
    // 	path: "/iadocument/needapproval/:id",
    // 	name: "iadocumentneedapproval",
    // 	component: IaDocumentInbox,
    // 	beforeEnter: authGuard,
    // },

    // ROUTE IADOCUMENT END

    //ENDROUTE
  ],
});
